import LocomotiveScroll from 'locomotive-scroll';

let scrollContainer = document.querySelector("[data-scroll-container]");
var scroll;

scroll = new LocomotiveScroll({
    el: scrollContainer,
    smooth: true,
    smartphone: {
        smooth: false,
    },
    tablet: {
        smooth: true
    }
});


// Scroll update all pages

document.addEventListener('DOMContentLoaded', function() {

    function ScrollUpdateDelay() {
        setTimeout(function(){ scroll.update(); }, 500);
    }

    ScrollUpdateDelay();
});